
export class HealthRiskFormOutModel {

	public readonly patientId: string;
	public readonly isLowPriority: boolean;
	public readonly comment?: string;

	constructor(
		patientId: string,
		isLowPriority: boolean,
		comment: string | undefined,
	) {
		// TODO: Guard check on defined
		this.patientId = patientId;
		this.isLowPriority = isLowPriority;
		this.comment = comment;
	}

}


