import { Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionModel, PageOptionsQuery } from '../../shared';
import { FailureModel } from '../../shared/failures/failure.model';
import { HealthRiskComboboxModel } from '../models/health-risk-combobox.model';
import { HealthRiskFilterModel } from '../models/health-risk-filter.model';
import { HealthRiskModel } from '../models/health-risk.model';
import { HealthRiskGetQuery } from '../models/queries/health-risk.get.query';

export abstract class HealthRiskQueryHandlerService {

	public abstract getAsDescribedComboboxes(
		query: HealthRiskFilterModel
	): Observable<Result<Array<HealthRiskComboboxModel>, FailureModel>>;

	public abstract getPagedCollection(
		query: PageOptionsQuery<HealthRiskFilterModel>
	): Observable<Result<PagedCollectionModel<HealthRiskModel, HealthRiskFilterModel>, FailureModel>>;

	public abstract get(
		query: HealthRiskGetQuery
	): Observable<Result<HealthRiskModel, FailureModel>>;

}
