import { HealthRiskModel } from '../health-risk.model';

export class HealthRiskFormInModel {

	public readonly patientId: string;
	public readonly isLowPriority: boolean;
	public readonly comment?: string;

	constructor(
		patientId: string,
		isLowPriority: boolean,
		comment: string | undefined,
	) {
		// TODO: Guard check on defined
		this.patientId = patientId;
		this.isLowPriority = isLowPriority;
		this.comment = comment;
	}

	public static createEmpty = (patientId: string): HealthRiskFormInModel => {
		return new HealthRiskFormInModel(
			patientId,
			undefined,
			undefined
		);
	}

	public static createFromExistingModel(model: HealthRiskModel): HealthRiskFormInModel {
		return new HealthRiskFormInModel(
			model.patientId,
			model.isLowPriority,
			model.comment
		);
	}

}

