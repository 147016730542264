import { TranslocoService } from '@ngneat/transloco';
import {
	HealthRiskComboboxDto, HealthRiskCreateParameterDto, HealthRiskDto, HealthRiskFilterDto,
	HealthRiskFindParameterDto, HealthRiskUpdateParameterDto
} from '@nmn-communication/health-risks';
import { PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { Guid, isArrayDefined, isStringDefinedAndNotEmpty, isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollectionWithoutDtoFilter, getPagedCollectionWithoutItemFilter } from '../databases/fake.utils';

export class HealthRiskFakeTable {

	private readonly database: FakeDatabase;
	public readonly data: Array<HealthRiskFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getAsCombobox(parameter: HealthRiskFindParameterDto): HealthRiskComboboxDto {
		const record = this.findAsCombobox(parameter);

		if (!isValueDefined(record)) {
			throw new Error('Record was not found');
		}

		return record;
	}

	public findAsCombobox(parameter: HealthRiskFindParameterDto): HealthRiskComboboxDto {
		const record = this.data
			.find((item: HealthRiskFakeRecord) => findPredicate(item, parameter));

		return isValueDefined(record) ? this.mapFromRecordToDescribedComboboxDto(record) : undefined;
	}

	public getComboboxesPagedCollection(filter: HealthRiskFilterDto): Array<HealthRiskComboboxDto> {
		return getPagedCollectionWithoutDtoFilter(
			this.translocoService,
			this.data,
			{ filter },
			filterPredicateForFakeRecord,
			this.mapFromRecordToDescribedComboboxDto.bind(this),
			compareAsComboboxesFn
		).items;
	}

	public find(findParameter: HealthRiskFindParameterDto): HealthRiskDto {
		const record = this.data
			.find((item: HealthRiskFakeRecord) => findPredicate(item, findParameter));

		return this.mapFromRecordToDto(record);
	}

	public create(parameter: HealthRiskCreateParameterDto): void {
		const records = mapFromCreateParameterToRecord(parameter);
		this.data.push(...records);
	}

	public update(
		findParameter: HealthRiskFindParameterDto,
		updateParameter: HealthRiskUpdateParameterDto
	): void {
		const record = this.data.find((item: HealthRiskFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Genetic Test to update was not found');
		}

		applyUpdateParameter(record!, updateParameter);
	}

	public delete(findParameter: HealthRiskFindParameterDto): void {
		const index = this.data
			.findIndex((item: HealthRiskFakeRecord) => findPredicate(item, findParameter));

		if (index >= 0) {
			this.data.splice(index, 1);
		}
	}

	public getPagedCollection(pageOptions: PageOptionsDto<HealthRiskFilterDto>): PagedCollectionDto<HealthRiskDto, HealthRiskFilterDto> {
		return getPagedCollectionWithoutItemFilter(
			this.translocoService,
			this.data,
			pageOptions,
			this.mapFromRecordToDto.bind(this),
			filterPredicateForDto,
			compareFn
		);
	}

	private mapFromRecordToDescribedComboboxDto(
		record: HealthRiskFakeRecord
	): HealthRiskComboboxDto {
		if (!isValueDefined(record)) {
			return undefined;
		}

		return {
			id: record.id,
			displayText: this.database.diseasesTable.findAsCombobox({ id: record.riskId }).displayText,
			description: record.comment,
			createdOn: record.createdOn,
			lastModifiedOn: record.lastModifiedOn,
			provider: record.provider,
			riskValue: record.riskValue,
			risk: this.database.diseasesTable.findAsCombobox({ id: record.riskId }),
			documents: this.database.documentsTable.getComboboxesPagedCollection({ ids: record.documentIds }),
		};
	}

	private mapFromRecordToDto(record: HealthRiskFakeRecord): HealthRiskDto {
		return {
			id: record.id,
			patientId: record.patientId,
			isLowPriority: record.isLowPriority,
			comment: record.comment,
			createdOn: record.createdOn,
			lastModifiedOn: record.lastModifiedOn,
			provider: record.provider,
			riskValue: record.riskValue,
			risk: this.database.diseasesTable.findAsCombobox({ id: record.riskId }),
			fileId: undefined,
			documents: this.database.documentsTable.getComboboxesPagedCollection({ ids: record.documentIds }),
		};
	}

}

const mapFromCreateParameterToRecord = (
	parameter: HealthRiskCreateParameterDto
): Array<HealthRiskFakeRecord> => {
	const operationDateTime = (new Date()).toISOString();

	return [{
		id: Guid.newGuid(),
		patientId: parameter.patientId,
		isLowPriority: false,
		comment: undefined,
		createdOn: operationDateTime,
		lastModifiedOn: operationDateTime,
		provider: 'Apixmed',
		riskValue: 8,
		riskId: 'headache',
		documentIds: []
	},
	{
		id: Guid.newGuid(),
		patientId: parameter.patientId,
		isLowPriority: false,
		comment: undefined,
		createdOn: operationDateTime,
		lastModifiedOn: operationDateTime,
		provider: 'Apixmed',
		riskValue: 7,
		riskId: 'pneumonia',
		documentIds: []
	}]
};

const applyUpdateParameter = (
	record: HealthRiskFakeRecord,
	updateParameter: HealthRiskUpdateParameterDto
): void => {
	const operationDateTime = (new Date()).toISOString();

	record.comment = updateParameter.comment;
	record.isLowPriority = updateParameter.isLowPriority;
	record.lastModifiedOn = operationDateTime;
};

const filterPredicateForDto = (
	item: HealthRiskDto,
	filter: HealthRiskFilterDto
): boolean => {
	let result = true;

	if (result && isStringDefinedAndNotEmpty(filter.searchPattern)) {
		result = result &&
			(
				item.risk.displayText.toUpperCase().indexOf(filter.searchPattern!.toUpperCase()) >= 0
			);
	}

	if (result && isArrayDefined(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.patientId)) {
		// TODO: implement to support patient aliases too
		// result = result && filter.patientId === item.patientId;
	}

	if (result && isArrayDefined(filter.riskValues)) {
		result = result && filter.riskValues.some(riskValue => item.riskValue === riskValue);
	}

	if (result && isArrayDefined(filter.riskIds)) {
		result = result && filter.riskIds.some(riskId => item.risk.id === riskId);
	}

	return result;
};
const filterPredicateForFakeRecord = (
	item: HealthRiskFakeRecord,
	filter: HealthRiskFilterDto
): boolean => {
	let result = true;

	if (result && isArrayDefined(filter.ids)) {
		result = result && filter.ids.some(id => item.id === id);
	}

	if (result && isArrayDefined(filter.ignoreIds)) {
		result = result && filter.ignoreIds.every(ignoreId => item.id !== ignoreId);
	}

	if (result && isStringDefinedAndNotEmpty(filter.patientId)) {
		// TODO: implement to support patient aliases too
		// result = result && filter.patientId === item.patientId;
	}

	if (result && isArrayDefined(filter.riskValues)) {
		result = result && filter.riskValues.some(riskValue => item.riskValue === riskValue);
	}

	if (result && isArrayDefined(filter.riskIds)) {
		result = result && filter.riskIds.some(riskId => item.id === riskId);
	}

	return result;
};

const findPredicate = (
	item: HealthRiskFakeRecord,
	findParameter: HealthRiskFindParameterDto
): boolean => item.id === findParameter.id;

/* eslint-disable  */
/* eslint-disable complexity */
const compareAsComboboxesFn = (
	item1: HealthRiskComboboxDto,
	item2: HealthRiskComboboxDto,
	sorting: string
): number => {
	if (sorting === 'displayText asc') {
		return item1.displayText > item2.displayText ? 1 : item1.displayText < item2.displayText ? -1 : 0;
	} else if (sorting === 'displayText desc') {
		return item1.displayText < item2.displayText ? 1 : item1.displayText > item2.displayText ? -1 : 0;
	}

	return 0;
};

const compareFn = (item1: HealthRiskDto, item2: HealthRiskDto, sorting: string): number => {
	if (sorting === 'risk asc') {

		return item1.risk.displayText > item2.risk.displayText ? 1 : item1.risk.displayText < item2.risk.displayText ? -1 : 0;
	} else if (sorting === 'createdOn asc') {

		return item1.createdOn > item2.createdOn ? 1 : item1.createdOn < item2.createdOn ? -1 : 0;
	} else if (sorting === 'lastModifiedOn desc') {

		return item1.lastModifiedOn < item2.lastModifiedOn ? 1 : item1.lastModifiedOn > item2.lastModifiedOn ? -1 : 0;
	} else if (sorting === 'riskValue asc') {

		return item1.riskValue > item2.riskValue ? 1 : item1.riskValue < item2.riskValue ? -1 : 0;
	} else if (sorting === 'riskValue desc') {

		return item1.riskValue < item2.riskValue ? 1 : item1.riskValue > item2.riskValue ? -1 : 0;
	}

	return 0;
};
/* eslint-enable complexity */
/* eslint-enable */

interface HealthRiskFakeRecord {

	id: string;
	patientId: string;
	isLowPriority: boolean;
	comment?: string;
	createdOn: string;
	lastModifiedOn: string;
	provider: string;
	riskValue: number;
	riskId: string;
	documentIds: Array<string>;
}

const initialData: Array<HealthRiskFakeRecord> = [
	{
		id: 'health-risk-fake-record-1',
		patientId: '00000000-0000-0000-0002-000000000001',
		isLowPriority: false,
		comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
		createdOn: '2024-09-15',
		lastModifiedOn: '2024-09-15',
		provider: 'Apixmed',
		riskValue: 9,
		riskId: 'type II diabetes mellitus without complications',
		documentIds: ['00000000-0000-0000-0201-000000000006']
	},
	{
		id: 'health-risk-fake-record-2',
		patientId: '00000000-0000-0000-0002-000000000001',
		isLowPriority: false,
		comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
		createdOn: '2024-09-15',
		lastModifiedOn: '2024-09-15',
		provider: 'Apixmed',
		riskValue: 8,
		riskId: 'heart failure',
		documentIds: ['00000000-0000-0000-0201-000000000006']
	}
];

// eslint-disable-next-line max-lines

