export class HealthRiskDeleteCommand {

	public readonly id: string;
	public readonly patientId: string;

	public constructor(
		id: string,
		patientId: string
	) {
		// TODO: add null check
		this.id = id;
		this.patientId = patientId;
	}

}

