import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DoctorEncounterFakeTable } from '../tables/doctor-encounter.fake.table';
import { DocumentAttachmentFakeTable } from '../tables/document-attachment.fake-table';
import { DocumentFakeTable } from '../tables/document.fake-table';
import { EmailConfirmationTokenFakeTable } from '../tables/email-confirmation-token.table';
import { GeneticFileParseRequestFakeTable } from '../tables/genetic-file-parse-request.fake-table';
import { GeneticTestFakeTable } from '../tables/genetic-test.fake-table';
import { HealthIssueFakeTable } from '../tables/health-issue.fake.table';
import { HealthRiskFakeTable } from '../tables/health-risk.fake.table';
import { AllergyIntoleranceCriticalityFakeTable } from '../tables/minor-tables/allergy-intolerance-criticality.fake-table';
import { AllergyIntoleranceStatusFakeTable } from '../tables/minor-tables/allergy-intolerance-status.fake-table';
import { AllergyIntoleranceVerificationStatusFakeTable } from '../tables/minor-tables/allergy-intolerance-verification-status.fake-table';
import { AllergyFakeTable } from '../tables/minor-tables/allergy.fake-table';
import { BloodTypeFakeTable } from '../tables/minor-tables/blood-type.fake-table';
import { DiseaseFakeTable } from '../tables/minor-tables/disease.fake-table';
import { DocumentTypeFakeTable } from '../tables/minor-tables/document-type.fake-table';
import { FoodFakeTable } from '../tables/minor-tables/food.fake-table';
import { GenderFakeTable } from '../tables/minor-tables/gender.fake-table';
import { GeneVariantFakeTable } from '../tables/minor-tables/gene-variant.fake-table';
import { GeneFakeTable } from '../tables/minor-tables/gene.fake-table';
import { HealthComplaintFakeTable } from '../tables/minor-tables/health-complaint.fake-table';
import { HealthIssueSeverityFakeTable } from '../tables/minor-tables/health-issue-severity.fake-table';
import { LanguageFakeTable } from '../tables/minor-tables/language.fake-table';
import { MedicationAdmRouteFakeTable } from '../tables/minor-tables/medication-adm-route.fake-table';
import { MedicationAdmStatusFakeTable } from '../tables/minor-tables/medication-adm-status.fake-table';
import { MedicationBodySiteFakeTable } from '../tables/minor-tables/medication-body-site.fake-table';
import { MedicationDosageTypeFakeTable } from '../tables/minor-tables/medication-dosage-type.fake-table';
import { MedicationRateQuantityTypeFakeTable } from '../tables/minor-tables/medication-rate-quantity-type.fake-table';
import { MedicationFakeTable } from '../tables/minor-tables/medication.fake-table';
import { PopulationFakeTable } from '../tables/minor-tables/population.fake-table';
import { RegionFakeTable } from '../tables/minor-tables/region.fake-table';
import { SubscriptionFakeTable } from '../tables/minor-tables/subscription.fake-table';
import { TimezoneFakeTable } from '../tables/minor-tables/timezone.fake-table';
import { TypeOfMedicationFakeTable } from '../tables/minor-tables/type-of-medication.fake-table';
import { VaccineFakeTable } from '../tables/minor-tables/vaccine.fake-table';
import { PatientFakeTable } from '../tables/patient.fake-table';
import { ResetPasswordTokenFakeTable } from '../tables/reset-password-token.table';
import { ServerConfigurationFakeTable } from '../tables/server-configuration.fake-table';
import { TakenMedicationFakeTable } from '../tables/taken-medication.fake-table';
import { TreatmentCheckerReportFakeTable } from '../tables/treatment-checker-report.fake-table';
import { UserConfigurationFakeTable } from '../tables/user-configuration.fake-table';
import { UserSubscriptionFakeTable } from '../tables/user-subscription.fake-table';
import { UserFakeTable } from '../tables/user.fake-table';
import { VaccinationFakeTable } from '../tables/vaccination.fake-table';

@Injectable({ providedIn: 'root' })
export class FakeDatabase {

	// minor tables

	public readonly allergyIntoleranceCriticalityTable: AllergyIntoleranceCriticalityFakeTable;
	public readonly allergyIntoleranceStatusTable: AllergyIntoleranceStatusFakeTable;
	public readonly allergyIntoleranceVerificationStatusTable: AllergyIntoleranceVerificationStatusFakeTable;
	public readonly allergyTable: AllergyFakeTable;
	public readonly bloodTypesTable: BloodTypeFakeTable;
	public readonly diseasesTable: DiseaseFakeTable;
	public readonly documentTypesTable: DocumentTypeFakeTable;
	public readonly foodTable: FoodFakeTable;
	public readonly gendersTable: GenderFakeTable;
	public readonly geneticFileParseRequestsTable: GeneticFileParseRequestFakeTable;
	public readonly genesTable: GeneFakeTable;
	public readonly geneVariantsTable: GeneVariantFakeTable;
	public readonly healthComplaintTable: HealthComplaintFakeTable;
	public readonly healthIssueSeverityTable: HealthIssueSeverityFakeTable;
	public readonly languageTable: LanguageFakeTable;
	public readonly medicationAdmRoutesTable: MedicationAdmRouteFakeTable;
	public readonly medicationAdmStatusesTable: MedicationAdmStatusFakeTable;
	public readonly medicationBodySitesTable: MedicationBodySiteFakeTable;
	public readonly medicationDosageTypesTable: MedicationDosageTypeFakeTable;
	public readonly medicationRateQuantityTypesTable: MedicationRateQuantityTypeFakeTable;
	public readonly medicationsTable: MedicationFakeTable;
	public readonly populationTable: PopulationFakeTable;
	public readonly regionsTable: RegionFakeTable;
	public readonly subscriptionFakeTable: SubscriptionFakeTable;
	public readonly typesOfMedicationTable: TypeOfMedicationFakeTable;
	public readonly vaccinesTable: VaccineFakeTable;

	// major tables

	public readonly emailConfirmationTokenTable: EmailConfirmationTokenFakeTable;
	public readonly userConfigurationFakeTable: UserConfigurationFakeTable;
	public readonly userSubscriptionFakeTable: UserSubscriptionFakeTable;
	public readonly usersTable: UserFakeTable;
	public readonly resetPasswordTokenTable: ResetPasswordTokenFakeTable;
	public readonly doctorEncounterTable: DoctorEncounterFakeTable;
	public readonly documentAttachmentsTable: DocumentAttachmentFakeTable;
	public readonly documentsTable: DocumentFakeTable;
	public readonly geneticTestsTable: GeneticTestFakeTable;
	public readonly healthIssueTable: HealthIssueFakeTable;
	public readonly healthRiskTable: HealthRiskFakeTable;
	public readonly patientsTable: PatientFakeTable;
	public readonly serverConfigurationTable: ServerConfigurationFakeTable;
	public readonly takenMedicationsTable: TakenMedicationFakeTable;
	public readonly timezoneTable: TimezoneFakeTable;
	public readonly treatmentCheckerReportsTable: TreatmentCheckerReportFakeTable;
	public readonly vaccinationTable: VaccinationFakeTable;

	constructor(
		private readonly translocoService: TranslocoService
	) {
		this.allergyIntoleranceCriticalityTable = new AllergyIntoleranceCriticalityFakeTable(this, this.translocoService);
		this.allergyIntoleranceStatusTable = new AllergyIntoleranceStatusFakeTable(this, this.translocoService);
		this.allergyIntoleranceVerificationStatusTable = new AllergyIntoleranceVerificationStatusFakeTable(this, this.translocoService);
		this.allergyTable = new AllergyFakeTable(this, this.translocoService);
		this.bloodTypesTable = new BloodTypeFakeTable(this, this.translocoService);
		this.diseasesTable = new DiseaseFakeTable(this, this.translocoService);
		this.documentTypesTable = new DocumentTypeFakeTable(this, this.translocoService);
		this.emailConfirmationTokenTable = new EmailConfirmationTokenFakeTable(this, this.translocoService);
		this.foodTable = new FoodFakeTable(this, this.translocoService);
		this.geneticFileParseRequestsTable = new GeneticFileParseRequestFakeTable(this, this.translocoService);
		this.geneticTestsTable = new GeneticTestFakeTable(this, this.translocoService);
		this.gendersTable = new GenderFakeTable(this, this.translocoService);
		this.geneVariantsTable = new GeneVariantFakeTable(this, this.translocoService);
		this.genesTable = new GeneFakeTable(this, this.translocoService);
		this.healthComplaintTable = new HealthComplaintFakeTable(this, this.translocoService);
		this.healthIssueSeverityTable = new HealthIssueSeverityFakeTable(this, this.translocoService);
		this.healthIssueTable = new HealthIssueFakeTable(this, this.translocoService);
		this.healthRiskTable = new HealthRiskFakeTable(this, this.translocoService);
		this.languageTable = new LanguageFakeTable(this, this.translocoService);
		this.medicationAdmRoutesTable = new MedicationAdmRouteFakeTable(this, this.translocoService);
		this.medicationAdmStatusesTable = new MedicationAdmStatusFakeTable(this, this.translocoService);
		this.medicationBodySitesTable = new MedicationBodySiteFakeTable(this, this.translocoService);
		this.medicationDosageTypesTable = new MedicationDosageTypeFakeTable(this, this.translocoService);
		this.medicationRateQuantityTypesTable = new MedicationRateQuantityTypeFakeTable(this, this.translocoService);
		this.medicationsTable = new MedicationFakeTable(this, this.translocoService);
		this.subscriptionFakeTable = new SubscriptionFakeTable(this, this.translocoService);
		this.typesOfMedicationTable = new TypeOfMedicationFakeTable(this, this.translocoService);
		this.vaccinesTable = new VaccineFakeTable(this, this.translocoService);
		this.userConfigurationFakeTable = new UserConfigurationFakeTable(this, this.translocoService);
		this.userSubscriptionFakeTable = new UserSubscriptionFakeTable(this, this.translocoService);
		this.usersTable = new UserFakeTable(this, this.translocoService);
		this.resetPasswordTokenTable = new ResetPasswordTokenFakeTable(this, this.translocoService);
		this.documentAttachmentsTable = new DocumentAttachmentFakeTable(this, this.translocoService);
		this.documentsTable = new DocumentFakeTable(this, this.translocoService);
		this.patientsTable = new PatientFakeTable(this, this.translocoService);
		this.populationTable = new PopulationFakeTable(this, this.translocoService);
		this.regionsTable = new RegionFakeTable(this, this.translocoService);
		this.serverConfigurationTable = new ServerConfigurationFakeTable(this);
		this.takenMedicationsTable = new TakenMedicationFakeTable(this, this.translocoService);
		this.timezoneTable = new TimezoneFakeTable(this, this.translocoService);
		this.treatmentCheckerReportsTable = new TreatmentCheckerReportFakeTable(this, this.translocoService);
		this.vaccinationTable = new VaccinationFakeTable(this, this.translocoService);
		this.doctorEncounterTable = new DoctorEncounterFakeTable(this, this.translocoService);
	}

}
