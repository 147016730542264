import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HealthRiskModel } from '../models/health-risk.model';

export abstract class HealthRiskResolver implements Resolve<HealthRiskModel> {

	public abstract resolve(
		activatedRoute: ActivatedRouteSnapshot,
		router: RouterStateSnapshot
	): Observable<HealthRiskModel> | HealthRiskModel;

}


