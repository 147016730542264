import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { HttpClientBuilder } from '../../shared/builders/http-client.builder';
import { HealthRiskHttpClientConfiguration } from '../configurations/health-risk.http-client.configuration';
import { HealthRiskHttpClient } from '../services/health-risk.http-client';

export class HealthRiskHttpClientBuilder
	extends HttpClientBuilder<HealthRiskHttpClient, HealthRiskHttpClientConfiguration> {

	constructor(
		private readonly injector: Injector
	) {
		super();
	}

	public build(): HealthRiskHttpClient {
		return new HealthRiskHttpClient(
			this.configuration,
			this.injector.get<HttpClient>(HttpClient));
	}

}
