import { Provider } from '@angular/core';
import { HealthRiskCommandHandlerService, HealthRiskQueryHandlerService, HealthRiskResolver } from '@nmn-domain/health-risks';
import { HealthRiskViaQueryHandlerResolver } from './resolvers/health-risk-via-query-handler.resolver';
import { HealthRiskCommandHandlerViaClientService } from './services/health-risk.command-handler-via-client.service';
import { HealthRiskQueryHandlerViaClientService } from './services/health-risk.query-handler-via-client.service';

export const healthRiskProviders: Array<Provider> = [
	{
		provide: HealthRiskQueryHandlerService,
		useClass: HealthRiskQueryHandlerViaClientService
	},
	{
		provide: HealthRiskCommandHandlerService,
		useClass: HealthRiskCommandHandlerViaClientService
	},
	{
		provide: HealthRiskResolver,
		useClass: HealthRiskViaQueryHandlerResolver
	}
];
