import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { ComboboxModel } from '../../shared';

export class HealthRiskFilterModel {

	public readonly patientId: string;
	public readonly ids?: Array<string>;
	public readonly ignoreIds?: Array<string>;
	public readonly searchPattern?: string;
	public readonly riskValues?: Array<number>;
	public readonly riskIds?: Array<string>;

	constructor(
		patientId: string,
		ids: Array<string> | undefined,
		ignoreIds: Array<string> | undefined,
		searchPattern: string | undefined,
		riskValues?: Array<number>,
		riskIds?: Array<string>
	) {
		this.patientId = patientId;
		this.ids = ids;
		this.ignoreIds = ignoreIds;
		this.searchPattern = searchPattern;
		this.riskValues = riskValues;
		this.riskIds = riskIds;
	}

	public static createForPredefinedList(
		patientId: string,
		healthRiskIds: Array<string>
	): HealthRiskFilterModel {
		return new HealthRiskFilterModel(
			patientId,
			healthRiskIds,
			undefined,
			undefined,
			undefined,
			undefined
		);
	}

	public static createForAutocomplete(
		patientId: string,
		searchPattern: string,
		selectedItem: ComboboxModel
	): HealthRiskFilterModel {
		return new HealthRiskFilterModel(
			patientId,
			undefined,
			isStringDefinedAndNotEmpty(selectedItem?.id) ? [selectedItem.id] : undefined,
			searchPattern,
			undefined,
			undefined
		);
	}

	public static createForMultiAutocomplete(
		patientId: string,
		searchPattern: string,
		ignoreIds: Array<string>
	): HealthRiskFilterModel {
		return new HealthRiskFilterModel(
			patientId,
			undefined,
			ignoreIds,
			searchPattern,
			undefined,
			undefined
		);
	}

}



