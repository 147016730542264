import { Injectable } from '@angular/core';
import { HealthRiskClient } from '@nmn-communication/health-risks';
import { Result } from '@nmn-core/shared';
import { HealthRiskCommandHandlerService, HealthRiskDeleteCommand, HealthRiskUpdateCommand } from '@nmn-domain/health-risks';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapUpdateCommandToParameter } from '../factories/health-risk-command.factory';

@Injectable()
export class HealthRiskCommandHandlerViaClientService extends HealthRiskCommandHandlerService {

	constructor(
		private readonly client: HealthRiskClient
	) {
		super();
	}

	public handleUpdateCommand(command: HealthRiskUpdateCommand): Observable<Result<EmptyCommandResult<HealthRiskUpdateCommand>, FailureModel>> {
		return this.client
			.update(
				{ id: command.id, patientId: command.patientId },
				mapUpdateCommandToParameter(command)
			)
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

	public handleDeleteCommand(command: HealthRiskDeleteCommand): Observable<Result<EmptyCommandResult<HealthRiskDeleteCommand>, FailureModel>> {
		return this.client
			.delete({ id: command.id, patientId: command.patientId })
			.pipe(
				map(result => result.map(mapFailureToFailureModel).toResult(() => new EmptyCommandResult(command)))
			);
	}

}


