import { TranslocoService } from '@ngneat/transloco';
import { ComboboxDto } from '@nmn-communication/shared';
import { isValueDefined } from '@nmn-core/utils';
import { DescribedComboboxModel } from '../../shared/comboboxes/described-combobox.model';

export class HealthRiskComboboxModel extends DescribedComboboxModel {

	public readonly createdOn: Date;
	public readonly lastModifiedOn: Date;
	public readonly riskValue: number;
	public readonly risk: ComboboxDto<string>;

	constructor(
		id: string,
		displayText: string,
		description: string,
		createdOn: Date,
		lastModifiedOn: Date,
		riskValue: number,
		risk: ComboboxDto<string>
	) {
		super(id, displayText, description);

		// TODO: Guard check on defined
		this.createdOn = createdOn;
		this.lastModifiedOn = lastModifiedOn;
		this.riskValue = riskValue;
		this.risk = risk;
	}

	public static createDisplayOptionFn(_: TranslocoService): (option: HealthRiskComboboxModel) => string | undefined {
		return (option: HealthRiskComboboxModel): string | undefined => {
			if (!isValueDefined(option)) {
				return undefined;
			}

			// const datePipe = new DatePipe(translocoService.getActiveLang());

			// if (option.isPeriodStartDefined && !option.isPeriodEndDefined) {
			// 	return translocoService?.translate(
			// 		'inlineViews.healthRisk.value_displayText_from',
			// 		{
			// 			displayText: option.displayText,
			// 			from: datePipe.transform(option.dateStart, 'yyyy-MM-dd', undefined, 'en-US')
			// 		}
			// 	);
			// }

			// if (!option.isPeriodStartDefined && option.isPeriodEndDefined) {
			// 	return translocoService?.translate(
			// 		'inlineViews.healthRisk.value_displayText_to',
			// 		{
			// 			displayText: option.displayText,
			// 			to: datePipe.transform(option.dateEnd, 'yyyy-MM-dd', undefined, 'en-US')
			// 		}
			// 	);
			// }

			// if (option.isPeriodStartDefined && option.isPeriodEndDefined) {
			// 	return translocoService?.translate(
			// 		'inlineViews.healthRisk.value_displayText_from_to',
			// 		{
			// 			displayText: option.displayText,
			// 			from: datePipe.transform(option.dateStart, 'yyyy-MM-dd', undefined, 'en-US'),
			// 			to: datePipe.transform(option.dateEnd, 'yyyy-MM-dd', undefined, 'en-US')
			// 		}
			// 	);
			// }

			return undefined;
		};
	}

}



