import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { PagedCollectionDto, PageOptionsDto } from '../../shared';
import { Failure } from '../../shared/error-handling/failure-envelope';
import { HealthRiskComboboxDto } from '../models/health-risk-combobox.dto';
import { HealthRiskFilterDto } from '../models/health-risk-filter.dto';
import { HealthRiskDto } from '../models/health-risk.dto';
import { HealthRiskFindParameterDto } from '../models/parameters/health-risk.find-parameter.dto';
import { HealthRiskUpdateParameterDto } from '../models/parameters/health-risk.update-parameter.dto';

export abstract class HealthRiskClient {

	public abstract getBulkAsComboboxes(
		filter: HealthRiskFilterDto
	): Observable<Result<Array<HealthRiskComboboxDto>, Failure>>;

	public abstract getBulk(
		parameter: PageOptionsDto<HealthRiskFilterDto>
	): Observable<Result<PagedCollectionDto<HealthRiskDto, HealthRiskFilterDto>, Failure>>;

	public abstract get(
		parameter: HealthRiskFindParameterDto
	): Observable<Result<HealthRiskDto, Failure>>;

	public abstract update(
		findParameter: HealthRiskFindParameterDto,
		updateParameter: HealthRiskUpdateParameterDto
	): Observable<EmptyResult<Failure>>;

	public abstract delete(
		parameter: HealthRiskFindParameterDto
	): Observable<EmptyResult<Failure>>;

	// TODO: add patch

}


