import { Injector } from '@angular/core';
import { isValueDefined } from '@nmn-core/utils';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { HealthRiskFakeClientConfiguration } from '../configurations/health-risk.fake-client.configuration';
import { HealthRiskFakeClient } from '../services/health-risk.fake-client';

export class HealthRiskFakeClientBuilder {

	private configuration: HealthRiskFakeClientConfiguration;

	constructor(
		private readonly injector: Injector
	) { }

	public withConfiguration(
		configuration: HealthRiskFakeClientConfiguration
	): HealthRiskFakeClientBuilder {
		if (isValueDefined(configuration)) {
			this.configuration = configuration;
		}

		return this;
	}

	public build(): HealthRiskFakeClient {
		return new HealthRiskFakeClient(
			this.configuration,
			this.injector.get(FakeDatabase));
	}

}
