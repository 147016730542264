import { ValueRestrictionOptional } from '../../../../../modules/core/configurations/restrictions/value-restriction-optional';

export interface HealthRiskFormConfiguration {
	restrictions: {
		comment: {
			length: ValueRestrictionOptional<number>;
			controlRowsCount: number;
		};
	};
}

export const defaultHealthRiskCreateFormConfiguration: HealthRiskFormConfiguration = {
	restrictions: {
		comment: {
			length: {
				max: 3000,
				min: 0
			},
			controlRowsCount: 5
		}
	}
};

export const defaultHealthRiskEditFormConfiguration: HealthRiskFormConfiguration = {
	restrictions: {
		...defaultHealthRiskCreateFormConfiguration.restrictions
	}
};

