import {
	HealthRiskClient,
	HealthRiskComboboxDto,
	HealthRiskCreateParameterDto,
	HealthRiskDto,
	HealthRiskFilterDto,
	HealthRiskFindParameterDto,
	HealthRiskUpdateParameterDto
} from '@nmn-communication/health-risks';
import { Failure, PagedCollectionDto, PageOptionsDto } from '@nmn-communication/shared';
import { EmptyResult, Result } from '@nmn-core/shared';
import { Observable } from 'rxjs';
import { FakeDatabase } from '../../../../modules/fakes/databases/fake.database';
import { wrapActionCallAsFakeClientResponse, wrapFuncCallAsFakeClientResponse } from '../../../../modules/fakes/databases/fake.utils';
import { HealthRiskFakeClientConfiguration } from '../configurations/health-risk.fake-client.configuration';

export class HealthRiskFakeClient extends HealthRiskClient {

	constructor(
		private readonly configuration: HealthRiskFakeClientConfiguration,
		private readonly fakeDatabase: FakeDatabase
	) {
		super();
	}

	public getBulkAsComboboxes(
		filter: HealthRiskFilterDto
	): Observable<Result<Array<HealthRiskComboboxDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthRiskTable.getComboboxesPagedCollection(filter),
			this.configuration.serverDelayMs);
	}

	public getBulk(
		parameter: PageOptionsDto<HealthRiskFilterDto>
	): Observable<Result<PagedCollectionDto<HealthRiskDto, HealthRiskFilterDto>, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthRiskTable.getPagedCollection(parameter),
			this.configuration.serverDelayMs);
	}

	public get(parameter: HealthRiskFindParameterDto): Observable<Result<HealthRiskDto, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthRiskTable.find(parameter),
			this.configuration.serverDelayMs);
	}

	public create(parameter: HealthRiskCreateParameterDto): Observable<Result<void, Failure>> {
		return wrapFuncCallAsFakeClientResponse(
			() => this.fakeDatabase.healthRiskTable.create(parameter),
			this.configuration.serverDelayMs);
	}

	public update(
		findParameter: HealthRiskFindParameterDto,
		updateParameter: HealthRiskUpdateParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.healthRiskTable.update(findParameter, updateParameter);
			},
			this.configuration.serverDelayMs);
	}

	public delete(
		parameter: HealthRiskFindParameterDto
	): Observable<EmptyResult<Failure>> {
		return wrapActionCallAsFakeClientResponse(
			() => {
				this.fakeDatabase.healthRiskTable.delete(parameter);
			},
			this.configuration.serverDelayMs);
	}

}



