import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { HealthRiskGetQuery, HealthRiskModel, HealthRiskQueryHandlerService, HealthRiskResolver } from '@nmn-domain/health-risks';
import { RouteParameter } from '@nmn-middleware/navigation';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HealthRiskViaQueryHandlerResolver extends HealthRiskResolver {

	constructor(
		private readonly healthRiskQueryHandler: HealthRiskQueryHandlerService
	) {
		super();
	}

	public resolve(activatedRoute: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<HealthRiskModel> | HealthRiskModel {
		const patientId = activatedRoute.paramMap.get(RouteParameter.PatientAlias);
		const healthRiskId = activatedRoute.paramMap.get(RouteParameter.HealthRiskId);

		if (!isStringDefinedAndNotEmpty(healthRiskId) || !isStringDefinedAndNotEmpty(patientId)) {
			return undefined;
		}

		return this.healthRiskQueryHandler
			.get(HealthRiskGetQuery.createById(healthRiskId, patientId))
			.pipe(
				map(result => result.successOrDefault(() => undefined))
			);
	}

}



