import { Injector, Provider } from '@angular/core';
import { HealthRiskClient } from '@nmn-communication/health-risks';
import { healthRiskClientFactory } from './factories/health-risk.client.factory';

export const healthRiskProviders: Array<Provider> = [
	{
		provide: HealthRiskClient,
		useFactory: healthRiskClientFactory,
		deps: [Injector]
	}
];



