import { HealthRiskFormOutModel } from '@nmn-domain/health-risks';


export class HealthRiskUpdateCommand {

	public readonly id: string;
	public readonly patientId: string;
	public readonly isLowPriority: boolean;
	public readonly comment?: string;

	constructor(
		id: string,
		patientId: string,
		isLowPriority: boolean,
		comment?: string
	) {
		// TODO: Guard check on defined
		this.id = id;
		this.patientId = patientId;
		this.isLowPriority = isLowPriority;
		this.comment = comment;
	}

	public static createByFormOutModel(id: string, formOutModel: HealthRiskFormOutModel): HealthRiskUpdateCommand {
		return new HealthRiskUpdateCommand(
			id,
			formOutModel.patientId,
			formOutModel.isLowPriority,
			formOutModel.comment
		);
	}

}


