import { Injectable } from '@angular/core';
import { HealthRiskClient } from '@nmn-communication/health-risks';
import { Result } from '@nmn-core/shared';
import { HealthRiskComboboxModel, HealthRiskFilterModel, HealthRiskGetQuery, HealthRiskModel, HealthRiskQueryHandlerService } from '@nmn-domain/health-risks';
import { FailureModel, PagedCollectionModel, PageOptionsQuery } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { mapFailureToFailureModel } from '../../shared/factories/failure-handling.factory';
import { mapDtoArrayResultToModelArrayResult, mapPageOptionsQueryToDto } from '../../shared/factories/pagination.factory';
import { mapHealthRiskComboboxDtoToModel } from '../factories/health-risk-combobox.factory';
import { mapDtoToModel, mapFilterModelToDto, mapHealthRiskPagedCollectionDtoToModel } from '../factories/health-risk.factory';

@Injectable()
export class HealthRiskQueryHandlerViaClientService extends HealthRiskQueryHandlerService {

	constructor(
		private readonly client: HealthRiskClient
	) {
		super();
	}

	public getAsDescribedComboboxes(filter: HealthRiskFilterModel): Observable<Result<Array<HealthRiskComboboxModel>, FailureModel>> {
		return this.client
			.getBulkAsComboboxes(mapFilterModelToDto(filter))
			.pipe(
				map(result => mapDtoArrayResultToModelArrayResult(result, mapHealthRiskComboboxDtoToModel))
			);
	}

	public getPagedCollection(query: PageOptionsQuery<HealthRiskFilterModel>): Observable<Result<PagedCollectionModel<HealthRiskModel, HealthRiskFilterModel>, FailureModel>> {
		return this.client
			.getBulk(mapPageOptionsQueryToDto(query, mapFilterModelToDto))
			.pipe(
				map(result => result.map(mapHealthRiskPagedCollectionDtoToModel, mapFailureToFailureModel))
			);
	}

	public get(query: HealthRiskGetQuery): Observable<Result<HealthRiskModel, FailureModel>> {
		return this.client
			.get({ id: query.id, patientId: query.patientId })
			.pipe(
				map(result => result.map(mapDtoToModel, mapFailureToFailureModel))
			);
	}

}


