import { TranslocoService } from '@ngneat/transloco';
import {
	GeneticFileParseRequestCreateParameterDto, GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto,
	GeneticFileParseRequestFindParameterDto, GeneticFileParseRequestUpdateParameterDto
} from '@nmn-communication/genetic-file-parse-requests';
import { Guid, isValueDefined } from '@nmn-core/utils';
import { FileType } from '@nmn-domain/file-uploader';
import { GeneticFileParseRequestStatus } from '@nmn-domain/genetic-file-parse-requests';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PagedCollectionDto, PageOptionsDto } from '../../core/clients';
import { FakeDatabase } from '../databases/fake.database';
import { getPagedCollectionWithoutItemFilter } from '../databases/fake.utils';

export class GeneticFileParseRequestFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<GeneticFileParseRequestFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public getPagedCollection(
		pageOptions: PageOptionsDto<GeneticFileParseRequestFilterDto>
	): PagedCollectionDto<GeneticFileParseRequestDto, GeneticFileParseRequestFilterDto> {
		const result = getPagedCollectionWithoutItemFilter(
			this.translocoService,
			this.data,
			pageOptions,
			this.mapGeneticFileParseRequestFromRecordToDto.bind(this),
			filterPredicate,
			compareFn);

		return result;
	}

	public get(findParameter: GeneticFileParseRequestFindParameterDto): GeneticFileParseRequestDto {
		const record = this.data
			.find((item: GeneticFileParseRequestFakeRecord) => findPredicate(item, findParameter));

		return isValueDefined(record) ?
			this.mapGeneticFileParseRequestFromRecordToDto(record!) :
			undefined!;
	}

	public create(parameter: GeneticFileParseRequestCreateParameterDto): GeneticFileParseRequestDto {
		const record = mapFromCreateParameterToRecord(parameter);
		this.data.push(record);

		// update status after timeouts here

		of(undefined)
			.pipe(delay(3000))
			.subscribe(_ => {
				record.status = GeneticFileParseRequestStatus.DocumentCreated;
				const patient = this.database.patientsTable.get({ alias: record.patientId });
				const documentId = this.addFakeDocuments(patient.id);
				this.addFakeHealthRisks(patient.id, documentId);
			});
		of(undefined)
			.pipe(delay(5000))
			.subscribe(_ => {
				record.status = GeneticFileParseRequestStatus.ParseFinished;
			});
		of(undefined)
			.pipe(delay(7000))
			.subscribe(_ => {
				record.status = GeneticFileParseRequestStatus.GeneticInfoCreated;
				const patient = this.database.patientsTable.get({ alias: record.patientId });
				this.addFakeParsedGeneticTests(patient.id);
			});
		of(undefined)
			.pipe(delay(9000))
			.subscribe(_ => {
				record.status = GeneticFileParseRequestStatus.Done;
				const patient = this.database.patientsTable.get({ alias: record.patientId });
			});

		return this.mapGeneticFileParseRequestFromRecordToDto(record);
	}

	private addFakeDocuments(patientId: string): string {
		return this.database.documentsTable.create(
			{
				patientId,
				name: 'Dna test result №2',
				description: undefined,
				typeId: 'labResults',
				attachmentFileIds: ['00000001-0000-0000-0000-000000000001'],
				relatedEncounterIds: [],
				relatedTakenMedicationIds: [],
				relatedHealthIssueIds: []
			}
		);
	}

	private addFakeParsedGeneticTests(patientId: string): void {
		this.database.geneticTestsTable.data.push(
			{
				id: Guid.newGuid(),
				patientId,
				date: (new Date()).toISOString(),
				geneId: 'CYP2C9',
				geneticTestResults: [{ haplotypeId: 'CYP2C9*1', diplotypeId: 'CYP2C9*1*2' }],
				comment: {
					en: 'Poor Metabolizer',
					uk: 'Поганий метаболізм'
				},
				certificateIssuer: {
					en: 'Ministry of Health, Welfare and Sports of the Netherlands',
					uk: 'Міністерство охорони здоров\'я, соціального забезпечення та спорту Нідерландів'
				},
				certificateUid: 'EU:NE:FJSJBVLDK83H8E0DAEC45010',
				createdOn: (new Date()).toISOString(),
				updatedOn: undefined,
				geneticTestCreationType: 'geneticFile'
			}
		);
	}

	private addFakeHealthRisks(patientId: string, documentId: string): void {
		this.database.healthRiskTable.data.push(
			{
				id: 'health-risk-fake-record-3',
				patientId: patientId,
				isLowPriority: false,
				comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
				createdOn: (new Date()).toISOString(),
				lastModifiedOn: (new Date()).toISOString(),
				provider: 'EasyDna',
				riskValue: 4,
				riskId: 'chronic obstructive pulmonary disease (COPD)',
				documentIds: [documentId]
			},
			{
				id: 'health-risk-fake-record-4',
				patientId: patientId,
				isLowPriority: false,
				comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
				createdOn: (new Date()).toISOString(),
				lastModifiedOn: (new Date()).toISOString(),
				provider: 'EasyDna',
				riskValue: 5,
				riskId: 'Diseases of the kidney',
				documentIds: [documentId]
			},
			{
				id: 'health-risk-fake-record-5',
				patientId: patientId,
				isLowPriority: false,
				comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
				createdOn: (new Date()).toISOString(),
				lastModifiedOn: (new Date()).toISOString(),
				provider: 'EasyDna',
				riskValue: 2,
				riskId: 'Headache',
				documentIds: [documentId]
			},
			{
				id: 'health-risk-fake-record-6',
				patientId: patientId,
				isLowPriority: false,
				comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
				createdOn: (new Date()).toISOString(),
				lastModifiedOn: (new Date()).toISOString(),
				provider: 'EasyDna',
				riskValue: 2,
				riskId: 'Anxiety disorders',
				documentIds: [documentId]
			},
			{
				id: 'health-risk-fake-record-7',
				patientId: patientId,
				isLowPriority: false,
				comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
				createdOn: (new Date()).toISOString(),
				lastModifiedOn: (new Date()).toISOString(),
				provider: 'EasyDna',
				riskValue: 1,
				riskId: 'Hepatopathy',
				documentIds: [documentId]
			},
			{
				id: 'health-risk-fake-record-8',
				patientId: patientId,
				isLowPriority: false,
				comment: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
				createdOn: (new Date()).toISOString(),
				lastModifiedOn: (new Date()).toISOString(),
				provider: 'EasyDna',
				riskValue: 1,
				riskId: 'Kidney stones',
				documentIds: [documentId]
			}
		);
	}

	public update(
		findParameter: GeneticFileParseRequestFindParameterDto,
		updateParameter: GeneticFileParseRequestUpdateParameterDto
	): string {
		const record = this.data
			.find((item: GeneticFileParseRequestFakeRecord) => findPredicate(item, findParameter));

		if (!isValueDefined(record)) {
			throw new Error('Genetic Test to update was not found');
		}
		applyUpdateParameter(this.translocoService, record!, updateParameter);

		return record.id;
	}

	private mapGeneticFileParseRequestFromRecordToDto(
		record: GeneticFileParseRequestFakeRecord
	): GeneticFileParseRequestDto {
		return {
			id: record.id,
			patientId: record.patientId,
			blob: {
				id: '00000001-0000-0000-0000-000000000001',
				downloadUrl: 'https://fakeimg.pl/image/200x200.png',
				fileName: 'fake-img',
				fileExtension: undefined,
				lengthInBytes: 1,
				createdOn: (new Date()).toISOString(),
				updatedOn: undefined!,
				fileType: FileType.Documents
			},
			status: record.status,
			isActive: record.isActive
		};
	}

}

const findPredicate = (
	item: GeneticFileParseRequestFakeRecord,
	findParameter: GeneticFileParseRequestFindParameterDto
): boolean =>
	item.id === findParameter.id;

const mapFromCreateParameterToRecord = (
	parameter: GeneticFileParseRequestCreateParameterDto
): GeneticFileParseRequestFakeRecord => (
	{
		id: Guid.newGuid(),
		patientId: parameter.patientId,
		isActive: true,
		blobId: parameter.blobId,
		status: 'created'
	}
);

const applyUpdateParameter = (
	_: TranslocoService,
	record: GeneticFileParseRequestFakeRecord,
	updateParameter: GeneticFileParseRequestUpdateParameterDto
): void => {
	record.status = updateParameter.status;
	record.isActive = updateParameter.isActive;
};

interface GeneticFileParseRequestFakeRecord {
	id: string;
	patientId: string;
	isActive: boolean;
	blobId: string;
	status: string;
}

const filterPredicate = (dto: GeneticFileParseRequestDto, filter: GeneticFileParseRequestFilterDto): boolean => {
	return isValueDefined(filter.isActive) && filter.isActive ?
		dto.isActive : true;
};

const compareFn = (_: GeneticFileParseRequestDto, __: GeneticFileParseRequestDto, ___: string): number => {
	return 0;
};

const initialData: Array<GeneticFileParseRequestFakeRecord> = [];
