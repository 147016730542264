import { TranslocoService } from '@ngneat/transloco';
import { DocumentAttachmentCreateParameter, DocumentAttachmentDto } from '@nmn-communication/documents';
import { Guid } from '@nmn-core/utils';
import { mapIconTypeFromString } from '@nmn-domain/file-uploader';
import { FakeDatabase } from '../databases/fake.database';

export class DocumentAttachmentFakeTable {

	private readonly database: FakeDatabase;
	private readonly data: Array<DocumentAttachmentFakeRecord>;

	constructor(
		database: FakeDatabase,
		private readonly translocoService: TranslocoService
	) {
		this.database = database;
		this.data = [...initialData];
	}

	public find(atachmentIds: Array<string>): Array<DocumentAttachmentDto> {
		const records = this.data
			.filter((item: DocumentAttachmentFakeRecord) => atachmentIds.indexOf(item.id) > -1);

		return records.map(this.mapFromRecordToDto.bind(this));
	}

	public create(parameter: DocumentAttachmentCreateParameter): string {
		const record = mapFromCreateParameterToRecord(parameter);
		this.data.push(record);

		return record.id;
	}

	public delete(id: string): void {
		const index = this.data
			.findIndex((item: DocumentAttachmentFakeRecord) => item.id === id);

		if (index >= 0) {
			this.data.splice(index, 1);
		}
	}

	public mapFromRecordToDto(record: DocumentAttachmentFakeRecord): DocumentAttachmentDto {
		return {
			id: record.id,
			file: {
				id: record.id,
				downloadUrl: record.downloadUrl,
				lengthInBytes: 1234,
				fileName: record.name,
				fileExtension: {
					id: record.extensionId,
					displayText: undefined,
					description: undefined,
					fileBlobIconType: mapIconTypeFromString(record.extensionId)
				},
				createdOn: record.createdOn,
				updatedOn: record.updatedOn,
				fileType: 'document'
			}
		};
	}

}

const mapFromCreateParameterToRecord = (
	parameter: DocumentAttachmentCreateParameter
): DocumentAttachmentFakeRecord => {

	// eslint-disable-next-line newline-per-chained-call
	// const fileExtension = `.${parameter.file.name.split('.').pop().toLowerCase()}`;
	console.log('Create attachment called (FAKE)', parameter);

	return {
		id: Guid.newGuid(),
		name: parameter.file.name,
		downloadUrl: 'https://fakeimg.pl/200/?text=CreatedAttachment',
		extensionId: 'doc',
		createdOn: (new Date()).toISOString(),
		updatedOn: undefined
	};
};

interface DocumentAttachmentFakeRecord {
	id: string;
	name: string;
	downloadUrl: string;
	extensionId: string;
	createdOn: string;
	updatedOn?: string;
}

// DocumentFakeRecord (initial data) has id mask 00000000-0000-0000-0202-************
const initialData: Array<DocumentAttachmentFakeRecord> = [
	{
		id: '00000000-0000-0000-0202-000000000001',
		name: 'Routine complete blood count.pdf',
		downloadUrl: 'https://www.oreilly.com/openbook/osfreesoft/book/ch02.pdf',
		extensionId: 'pdf',
		createdOn: '2020-10-11T12:00:00Z',
		updatedOn: '2021-03-07T17:00:00Z'
	},
	{
		id: '00000000-0000-0000-0202-000000000002',
		name: 'X-RAY combined.dicom',
		downloadUrl: 'https://fakeimg.pl/600/?text=Picture',
		extensionId: 'png',
		createdOn: '2020-11-10T12:00:00Z',
		updatedOn: '2021-02-17T17:00:00Z'
	},
	{
		id: '00000000-0000-0000-0202-000000000003',
		name: 'Dexlansoprazole_6DGFS.pdf',
		downloadUrl: 'https://fakeimg.pl/600/?text=Picture',
		extensionId: 'png',
		createdOn: '2020-12-01T13:00:00Z',
		updatedOn: '2021-02-06T14:00:00Z'
	},
	{
		id: '00000000-0000-0000-0202-000000000004',
		name: '2018-01-15_YS872533_report_43241.doc',
		downloadUrl: 'https://fakeimg.pl/400/?text=Picture',
		extensionId: 'png',
		createdOn: '2020-12-03T10:00:00Z',
		updatedOn: '2021-02-12T19:00:00Z'
	},
	{
		id: '00000000-0000-0000-0202-000000000005',
		name: 'test_attachment.png',
		downloadUrl: 'https://fakeimg.pl/400/?text=Picture',
		extensionId: 'png',
		createdOn: '2020-12-03T10:00:00Z',
		updatedOn: '2021-02-12T19:00:00Z'
	},
	{
		id: '00000000-0000-0000-0202-000000000006',
		name: 'test_attachment_3.png',
		downloadUrl: 'https://fakeimg.pl/400/?text=Picture',
		extensionId: 'png',
		createdOn: '2020-12-03T09:00:00Z',
		updatedOn: '2021-02-12T20:00:00Z'
	},
	// for autogeneration for genetic file
	{
		id: '00000000-0000-0000-0202-000000000007',
		name: 'geneticdata.vcf',
		downloadUrl: 'https://fakeimg.pl/400/?text=Picture',
		extensionId: 'vcf',
		createdOn: '2022-12-03T09:00:00Z',
		updatedOn: '2022-02-12T20:00:00Z'
	},
	{
		id: '00000000-0000-0000-0202-000000000008',
		name: 'geneticdata.vcf',
		downloadUrl: 'https://fakeimg.pl/400/?text=Picture',
		extensionId: 'vcf',
		createdOn: '2024-09-01T09:00:00Z',
		updatedOn: '2024-09-02T09:00:00Z'
	}
];
