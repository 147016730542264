import { HttpClientConfigurationBuilder } from '../../shared/builders/http-client.configuration.builder';
import { HealthRiskHttpClientConfiguration } from '../configurations/health-risk.http-client.configuration';

export class HealthRiskHttpClientConfigurationBuilder
	extends HttpClientConfigurationBuilder<HealthRiskHttpClientConfiguration> {

	public build(): HealthRiskHttpClientConfiguration {
		return new HealthRiskHttpClientConfiguration(
			this.baseUrl,
			this.resourceRelativePath,
			this.resourceTypeVersion);
	}

}
