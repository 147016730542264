import { HealthRiskComboboxDto } from '@nmn-communication/health-risks';
import { mapDateInServerFormatToDate } from '@nmn-core/utils';
import { HealthRiskComboboxModel } from '@nmn-domain/health-risks';

export const mapHealthRiskComboboxDtoToModel = (dto: HealthRiskComboboxDto): HealthRiskComboboxModel => (
	new HealthRiskComboboxModel(
		dto.id,
		dto.displayText,
		dto.description,
		mapDateInServerFormatToDate(dto.createdOn),
		mapDateInServerFormatToDate(dto.lastModifiedOn),
		dto.riskValue,
		dto.risk
	)
);
