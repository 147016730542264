import { Injectable } from '@angular/core';
import { ApplicationLanguageService } from '@nmn-background/application-language';
import { getCurrentBrowser, getCurrentBrowserTimezone, getCurrentBrowserTimezoneOffset, isValueDefined } from '@nmn-core/utils';
import { StorageService } from 'app/services';
import { GaScreenType } from '../../models/ga-screen-type.enum';
import { GaUserType } from '../../models/ga-user-type.enum';
import { GoogleAnalyticsEvent } from '../../models/google-analytics-event.enum';
import { GoogleAnalyticsService } from '../google-analytics.service';
import { GaEventTrackingAccountService } from './ga-event-tracking.account';
import { GaEventTrackingDocumentService } from './ga-event-tracking.document';
import { GaEventTrackingPurchaseService } from './ga-event-tracking.ecommerce';
import { GaEventTrackingGeneralService } from './ga-event-tracking.general';
import { GaEventTrackingGeneticCardService } from './ga-event-tracking.genetic-card';
import { GaEventTrackingHealthRiskService } from './ga-event-tracking.health-risk';
import { GaEventTrackingMedicalCardService } from './ga-event-tracking.medical-card';
import { GaEventTrackingPatientService } from './ga-event-tracking.patient';
import { GaEventTrackingTreatmentCheckerService } from './ga-event-tracking.treatment-checker';
import { GaEventTrackingVaccinationCardService } from './ga-event-tracking.vaccination-card';

@Injectable()
export class GoogleAnalyticsEventTrackingService {

	private readonly gaEventTrackingCustomerAccountService: GaEventTrackingAccountService;
	public get account(): GaEventTrackingAccountService { return this.gaEventTrackingCustomerAccountService; }

	private readonly gaEventTrackingDocumentService: GaEventTrackingDocumentService;
	public get document(): GaEventTrackingDocumentService { return this.gaEventTrackingDocumentService; }

	private readonly gaEventTrackingGeneralService: GaEventTrackingGeneralService;
	public get general(): GaEventTrackingGeneralService { return this.gaEventTrackingGeneralService; }

	private readonly gaEventTrackingGeneticCardService: GaEventTrackingGeneticCardService;
	public get geneticCard(): GaEventTrackingGeneticCardService { return this.gaEventTrackingGeneticCardService; }

	private readonly gaEventTrackingMedicalCardService: GaEventTrackingMedicalCardService;
	public get medicalCard(): GaEventTrackingMedicalCardService { return this.gaEventTrackingMedicalCardService; }

	private readonly gaEventTrackingHealthRiskService: GaEventTrackingHealthRiskService;
	public get healthRisk(): GaEventTrackingHealthRiskService { return this.gaEventTrackingHealthRiskService; }

	private readonly gaEventTrackingPatientService: GaEventTrackingPatientService;
	public get patient(): GaEventTrackingPatientService { return this.gaEventTrackingPatientService; }

	private readonly gaEventTrackingEcommerceService: GaEventTrackingPurchaseService;
	public get ecommerce(): GaEventTrackingPurchaseService { return this.gaEventTrackingEcommerceService; }

	private readonly gaEventTrackingTreatmentCheckerService: GaEventTrackingTreatmentCheckerService;
	public get treatmentChecker(): GaEventTrackingTreatmentCheckerService { return this.gaEventTrackingTreatmentCheckerService; }

	private readonly gaEventTrackingVaccinationCardService: GaEventTrackingVaccinationCardService;
	public get vaccinationCard(): GaEventTrackingVaccinationCardService { return this.gaEventTrackingVaccinationCardService; }

	private get screenInfo(): any {
		const screenHeight = window.innerHeight;
		const screenWidth = window.innerWidth;
		let screenType: GaScreenType;

		if (screenWidth < 600) {
			screenType = GaScreenType.Xs;
		} else if (screenWidth < 960) {
			screenType = GaScreenType.Sm;
		} else if (screenWidth < 1280) {
			screenType = GaScreenType.Md;
		} else if (screenWidth < 1920) {
			screenType = GaScreenType.Lg;
		} else {
			screenType = GaScreenType.Xl;
		}

		return {
			nmn__screen_type: screenType,
			nmn__screen_height: screenHeight,
			nmn__screen_width: screenWidth
		};
	}

	private get userInfo(): any {
		return {
			nmn__browser: getCurrentBrowser(),
			nmn__browser_language: this.applicationLanguageService.currentBrowserLanguage,
			nmn__browser_timezone: getCurrentBrowserTimezone(),
			nmn__browser_timezone_offset: getCurrentBrowserTimezoneOffset(),
			nmn__app_language: this.applicationLanguageService.currentApplicationLanguage,
			nmn__app_theme: 'Default',
			nmn__user_type: this.storageService.isLoggedIn ? GaUserType.Customer : GaUserType.Anonymous,
			nmn__user_subscription: this.storageService.userProfile?.subscriptionType,
			...this.screenInfo
		};
	}

	constructor(
		private readonly googleAnalyticsService: GoogleAnalyticsService,
		private readonly applicationLanguageService: ApplicationLanguageService,
		private readonly storageService: StorageService,
	) {
		this.gaEventTrackingCustomerAccountService = new GaEventTrackingAccountService(this.trackEvent.bind(this));
		this.gaEventTrackingDocumentService = new GaEventTrackingDocumentService(this.trackEvent.bind(this));
		this.gaEventTrackingGeneralService = new GaEventTrackingGeneralService(this.trackEvent.bind(this));
		this.gaEventTrackingGeneticCardService = new GaEventTrackingGeneticCardService(this.trackEvent.bind(this));
		this.gaEventTrackingMedicalCardService = new GaEventTrackingMedicalCardService(this.trackEvent.bind(this));
		this.gaEventTrackingPatientService = new GaEventTrackingPatientService(this.trackEvent.bind(this));
		this.gaEventTrackingEcommerceService = new GaEventTrackingPurchaseService(this.trackEvent.bind(this));
		this.gaEventTrackingTreatmentCheckerService = new GaEventTrackingTreatmentCheckerService(this.trackEvent.bind(this));
		this.gaEventTrackingVaccinationCardService = new GaEventTrackingVaccinationCardService(this.trackEvent.bind(this));
	}

	private trackEvent(event: GoogleAnalyticsEvent, data?: any, completeFn?: () => void): void {
		this.googleAnalyticsService
			.trackEvent(
				event,
				isValueDefined(data) ? { ...this.userInfo, ...data } : { ...this.userInfo },
				completeFn
			);
	}

}
