export enum Parameter {

	DoctorEncounterId = 'doctorEncounterId',
	DocumentId = 'documentId',
	HealthIssueId = 'healthIssueId',
	HealthRiskId = 'healthRiskId',
	GeneticTestId = 'geneticTestId',
	PatientAlias = 'patientAlias',
	CheckerResultId = 'resultId',
	TakenMedicationId = 'takenMedicationId',
	VaccinationId = 'vaccinationId',

	OrganizationId = 'organizationId'

}
