import { isValueDefined } from '@nmn-core/utils';
import { NmnMatTableUserConfiguration } from '../component-configurations/nmn-mat-table-user.configuration';

export class HealthRisksPageConfiguration {

	public readonly healthRisksTableUserConfiguration: NmnMatTableUserConfiguration;

	protected constructor(
		healthRisksTableUserConfiguration: NmnMatTableUserConfiguration
	) {
		this.healthRisksTableUserConfiguration = healthRisksTableUserConfiguration;
	}

	public static createWithParameters(
		config?: HealthRisksPageConfiguration
	): HealthRisksPageConfiguration {
		if (!isValueDefined(config)) {
			HealthRisksPageConfiguration.createDefault();
		}

		return new HealthRisksPageConfiguration(
			isValueDefined(config?.healthRisksTableUserConfiguration) ?
				NmnMatTableUserConfiguration.createWithParameters(config.healthRisksTableUserConfiguration) :
				NmnMatTableUserConfiguration.createDefault()
		);
	}

	public static createDefault(): HealthRisksPageConfiguration {
		return new HealthRisksPageConfiguration(
			NmnMatTableUserConfiguration.createWithParameters(
				new NmnMatTableUserConfiguration(undefined, undefined, undefined, 'desc', 'period'))
		);
	}

}
