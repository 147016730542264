export class HealthRiskFormFailure {

	public readonly isValidationFailure: boolean;

	private constructor(
		isValidationFailure: boolean
	) {
		this.isValidationFailure = isValidationFailure;
	}

	public static createAsValidationFailure(): HealthRiskFormFailure {
		return new HealthRiskFormFailure(true);
	}

}

