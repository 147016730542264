import { HealthRiskDto, HealthRiskFilterDto } from '@nmn-communication/health-risks';
import { PagedCollectionDto } from '@nmn-communication/shared';
import { mapDateInServerFormatToDate } from '@nmn-core/utils';
import { HealthRiskFilterModel, HealthRiskModel } from '@nmn-domain/health-risks';
import { PagedCollectionModel } from '@nmn-domain/shared';
import { mapDocumentDescribedComboboxDtoToModel } from '../../documents/factories/document.factory';
import { mapPagedCollectionDtoToModel } from '../../shared/factories/pagination.factory';

export const mapDtoToModel = (dto: HealthRiskDto): HealthRiskModel => (
	new HealthRiskModel(
		dto.id,
		dto.patientId,
		dto.isLowPriority,
		dto.comment,
		mapDateInServerFormatToDate(dto.createdOn),
		mapDateInServerFormatToDate(dto.lastModifiedOn),
		dto.provider,
		dto.riskValue,
		dto.risk,
		dto.fileId,
		dto.documents.map(mapDocumentDescribedComboboxDtoToModel)
	)
);

export const mapHealthRiskPagedCollectionDtoToModel = (
	pagedCollectionDto: PagedCollectionDto<HealthRiskDto, HealthRiskFilterDto>
): PagedCollectionModel<HealthRiskModel, HealthRiskFilterModel> =>
	mapPagedCollectionDtoToModel(pagedCollectionDto, mapDtoToModel, mapFilterDtoToModel);

export const mapFilterModelToDto = (model: HealthRiskFilterModel): HealthRiskFilterDto => (
	{
		patientId: model.patientId,
		ids: model.ids,
		ignoreIds: model.ignoreIds,
		searchPattern: model.searchPattern,
		riskValues: model.riskValues,
		riskIds: model.riskIds
	}
);

export const mapFilterDtoToModel = (dto: HealthRiskFilterDto): HealthRiskFilterModel => (
	new HealthRiskFilterModel(
		dto.patientId,
		dto.ids,
		dto.ignoreIds,
		dto.searchPattern,
		dto.riskValues,
		dto.riskIds,
	)
);
