import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { accountsConfirmEmailProviders } from './implementations/accounts/confirm-email/providers';
import { accountsLoginProviders } from './implementations/accounts/login/providers';
import { accountsRegistrationProviders } from './implementations/accounts/registration/providers';
import { accountsResetPasswordProviders } from './implementations/accounts/reset-password/providers';
import { accountsUserProfileProviders } from './implementations/accounts/user-accounts/providers';
import { accountsUserConfigurationProviders } from './implementations/accounts/user-configuration/providers';
import { accountsUserSubscriptionProviders } from './implementations/accounts/user-subscription/providers';
import { allergyProviders } from './implementations/allergies/providers';
import { allergyIntoleranceCriticalityProviders } from './implementations/allergy-intolerance-criticalities/providers';
import { allergyIntoleranceStatusProviders } from './implementations/allergy-intolerance-statuses/providers';
import { allergyIntoleranceVerificationStatusProviders } from './implementations/allergy-intolerance-verification-statuses/providers';
import { bloodTypeProviders } from './implementations/blood-types/providers';
import { contactsProviders } from './implementations/contacts/providers';
import { diseaseProviders } from './implementations/diseases/providers';
import { doctorEncounterProviders } from './implementations/doctor-encounters/providers';
import { documentTypeProviders } from './implementations/document-types/providers';
import { documentProviders } from './implementations/documents/providers';
import { fileUploaderProviders } from './implementations/file-uploader/providers';
import { genderProviders } from './implementations/genders/providers';
import { geneVariantProviders } from './implementations/gene-variants/providers';
import { geneProviders } from './implementations/genes/providers';
import { geneticFileParseRequestProviders } from './implementations/genetic-file-parse-requests/providers';
import { geneticTestProviders } from './implementations/genetic-tests/providers';
import { healthComplaintProviders } from './implementations/health-complaints/providers';
import { healthIssueSeverityProviders } from './implementations/health-issue-severities/providers';
import { healthIssueProviders } from './implementations/health-issues/providers';
import { healthRiskProviders } from './implementations/health-risks/providers';
import { languageProviders } from './implementations/languages/providers';
import { localeProviders } from './implementations/locales/providers';
import { medicationAdmRouteProviders } from './implementations/medication-adm-routes/providers';
import { medicationAdmStatusProviders } from './implementations/medication-adm-statuses/providers';
import { medicationBodySiteProviders } from './implementations/medication-body-sites/providers';
import { medicationDosageTypeProviders } from './implementations/medication-dosage-types/providers';
import { medicationRateQuantityTypeProviders } from './implementations/medication-rate-quantity-types/providers';
import { medicationProviders } from './implementations/medications/providers';
import { organizationProviders } from './implementations/organizations/providers';
import { patientProviders } from './implementations/patients/providers';
import { populationProviders } from './implementations/populations/providers';
import { regionProviders } from './implementations/regions/providers';
import { serverConfigurationProviders } from './implementations/server-configuration/providers';
import { takenMedicationProviders } from './implementations/taken-medications/providers';
import { timezoneProviders } from './implementations/timezones/providers';
import { treatmentCheckerProviders } from './implementations/treatment-checker/providers';
import { typeOfMedicationProviders } from './implementations/types-of-medication/providers';
import { vaccinationProviders } from './implementations/vaccination/providers';
import { vaccineProviders } from './implementations/vaccines/providers';

@NgModule({
	declarations: [],
	providers: [
		...accountsConfirmEmailProviders,
		...accountsLoginProviders,
		...accountsRegistrationProviders,
		...accountsResetPasswordProviders,
		...accountsUserProfileProviders,
		...accountsUserConfigurationProviders,
		...accountsUserSubscriptionProviders,
		...allergyProviders,
		...allergyIntoleranceCriticalityProviders,
		...allergyIntoleranceStatusProviders,
		...allergyIntoleranceVerificationStatusProviders,
		...bloodTypeProviders,
		...contactsProviders,
		...diseaseProviders,
		...doctorEncounterProviders,
		...documentProviders,
		...documentTypeProviders,
		...fileUploaderProviders,
		...geneticFileParseRequestProviders,
		...genderProviders,
		...geneVariantProviders,
		...geneProviders,
		...geneticTestProviders,
		...healthComplaintProviders,
		...healthIssueSeverityProviders,
		...healthIssueProviders,
		...healthRiskProviders,
		...languageProviders,
		...localeProviders,
		...medicationAdmRouteProviders,
		...medicationAdmStatusProviders,
		...medicationBodySiteProviders,
		...medicationDosageTypeProviders,
		...medicationRateQuantityTypeProviders,
		...medicationProviders,
		...organizationProviders,
		...patientProviders,
		...populationProviders,
		...regionProviders,
		...serverConfigurationProviders,
		...takenMedicationProviders,
		...timezoneProviders,
		...treatmentCheckerProviders,
		...typeOfMedicationProviders,
		...vaccinationProviders,
		...vaccineProviders
	],
	imports: [
		CommonModule
	]
})
export class DomainModule { }
