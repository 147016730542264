import { DocumentDescribedComboboxDto } from '@nmn-communication/documents';
import { ComboboxDto } from '@nmn-communication/shared';
import { isArrayDefinedAndNotEmpty, isStringDefinedAndNotEmpty } from '@nmn-core/utils';
import { DocumentDescribedComboboxModel } from '../../documents';

export class HealthRiskModel {

	public readonly id: string;
	public readonly patientId: string;
	public readonly isLowPriority: boolean;
	public readonly comment?: string;
	public readonly createdOn: Date;
	public readonly lastModifiedOn: Date;
	public readonly provider: string;
	public readonly riskValue: number;
	public readonly risk: ComboboxDto<string>;
	public readonly fileId: string;
	public readonly documents: Array<DocumentDescribedComboboxDto>;

	public get isDocumentsDefined(): boolean {
		return isArrayDefinedAndNotEmpty(this.documents);
	}

	public get isCommentDefined(): boolean {
		return isStringDefinedAndNotEmpty(this.comment);
	}

	constructor(
		id: string,
		patientId: string,
		isLowPriority: boolean,
		comment: string | undefined,
		createdOn: Date,
		lastModifiedOn: Date,
		provider: string,
		riskValue: number,
		risk: ComboboxDto<string>,
		fileId: string,
		documents: Array<DocumentDescribedComboboxModel>
	) {
		// TODO: Guard check on defined
		this.id = id;
		this.patientId = patientId;
		this.isLowPriority = isLowPriority;
		this.comment = comment;
		this.createdOn = createdOn;
		this.lastModifiedOn = lastModifiedOn;
		this.provider = provider;
		this.riskValue = riskValue;
		this.risk = risk;
		this.fileId = fileId;
		this.documents = documents;
	}

}
