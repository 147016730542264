import { Result } from '@nmn-core/shared';
import { FailureModel } from '@nmn-domain/shared';
import { Observable } from 'rxjs';
import { EmptyCommandResult } from '../../../../modules/core/models/commands/command-result';
import { HealthRiskDeleteCommand } from '../models/commands/health-risk.delete.command';
import { HealthRiskUpdateCommand } from '../models/commands/health-risk.update.command';

export abstract class HealthRiskCommandHandlerService {

	public abstract handleDeleteCommand(command: HealthRiskDeleteCommand): Observable<Result<EmptyCommandResult<HealthRiskDeleteCommand>, FailureModel>>;

	public abstract handleUpdateCommand(command: HealthRiskUpdateCommand): Observable<Result<EmptyCommandResult<HealthRiskUpdateCommand>, FailureModel>>;

}
