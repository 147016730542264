import { Injector } from '@angular/core';
import { HealthRiskClient } from '@nmn-communication/health-risks';
import { isValueDefined } from '@nmn-core/utils';
import { environment } from 'environments/environment';
import { HealthRiskFakeClientBuilder } from '../builders/health-risk.fake-client.builder';
import { HealthRiskFakeClientConfigurationBuilder } from '../builders/health-risk.fake-client.configuration.builder';
import { HealthRiskHttpClientBuilder } from '../builders/health-risk.http-client.builder';
import { HealthRiskHttpClientConfigurationBuilder } from '../builders/health-risk.http-client.configuration.builder';
import { HealthRiskFakeClient } from '../services/health-risk.fake-client';
import { HealthRiskHttpClient } from '../services/health-risk.http-client';

export const healthRiskClientFactory = (injector: Injector): HealthRiskClient => {
	const useFake = environment?.api?.resources?.healthRisks?.useFake === true ||
		!isValueDefined(environment?.api?.resources?.healthRisks?.useFake) &&
		environment.api.defaultUseFake;

	return useFake ?
		healthRiskFakeClientFactory(injector) :
		healthRiskHttpClientFactory(injector);
};

export const healthRiskFakeClientFactory = (
	injector: Injector
): HealthRiskFakeClient => {
	const configuration = new HealthRiskFakeClientConfigurationBuilder()
		.withServerDelay(environment.api.resources.healthRisks.serverDelayMs)
		.build();

	return new HealthRiskFakeClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

export const healthRiskHttpClientFactory = (
	injector: Injector
): HealthRiskHttpClient => {
	const configuration = new HealthRiskHttpClientConfigurationBuilder()
		.withBaseUrl(
			environment?.api?.resources?.healthRisks?.baseUrl ??
			environment?.api?.defaultBaseUrl)
		.withResourceRelativePath(environment.api.resources.healthRisks.resourceRelativePath)
		.withResourceTypeVersion(
			environment?.api?.resources?.healthRisks?.resourceTypeVersion ??
			environment?.api?.defaultResourceTypeVersion)
		.build();

	return new HealthRiskHttpClientBuilder(injector)
		.withConfiguration(configuration)
		.build();
};

